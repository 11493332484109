import "./MyHackathons.css";
import Remove1 from "../../../assets/Images/MyHackathons/Remove1.svg";
import Remove2 from "../../../assets/Images/MyHackathons/Remove2.svg";
import rankingLogo2 from "../../../assets/Icons/MyHackathons/rankingLogo2.svg";
import rankingLogo3 from "../../../assets/Icons/MyHackathons/rankingLogo3.svg";

import hackathoneRanking1 from "../../../assets/Images/MyHackathons/hackathoneRanking1.svg";
import hackathonPrize from "../../../assets/Images/MyHackathons/hackathonPrize.svg";
import hackathoneTime from "../../../assets/Images/MyHackathons/hackathoneTime.svg";
import Header from "../../../components/Header/Header";
import Navbar from "../../../components/Navbar/Navbar";
import AddIcon from "../../../assets/Icons/Jobs/Group1187.svg";
import React, { useEffect, useState } from "react";

import axios from "axios";
import { API, BootcampBackend } from "../../../backend";
import { useHistory } from "react-router-dom";
import { LoadingShape } from "react-rainbow-components";
import RequestHackathon from "./RequestHackathon/RequestHackathon";
import { useSidebar } from "helper/SideBarContext";

const moment = require("moment");
export default function MyHackathons() {
  const [hackathons, setHackathons] = useState([]);

  const [show, setShow] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [userData, setUserData] = useState({
    name: "",
    emailId: "",
    phone: "",
    description: "",
    designation: "",
    organization: "",
  });

  const [userError, setUserError] = useState({
    emailId: false,
    name: false,
  });

  const { isSidebarCollapsed } = useSidebar();

  const history = useHistory();

  const formatDate = (dateTime) => {
    var string = dateTime;

    var temp = string.split(" ");
    var strdate = temp[0].split("/");

    var year = strdate[2];
    var month = strdate[1];
    var day = strdate[0];

    var formattedDate = moment({
      year: Number(year),
      month: Number(month) - 1,
      day: Number(day),
    }).format("MMM DD");

    return formattedDate;
  };

  function showModal() {
    setShow(true);
  }
  function hideModal() {
    setUserData({
      name: "",
      emailId: "",
      phone: "",
      description: "",
      designation: "",
      organization: "",
    });
    setShow(false);
  }

  function handleChange(event) {
    setUserError({
      emailId: false,
      name: false,
    });

    const { name, value } = event.target;
    setUserData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    var regName = /^[a-zA-Z\s]{1,30}$/;
    var regEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!regName.test(userData.name)) {
      setUserError({
        name: true,
      });
      return;
    }

    if (!regEmail.test(userData.emailId)) {
      setUserError({
        emailId: true,
      });
      return;
    }
    if (!regName.test(userData.designation)) {
      setUserError({
        designation: true,
      });
      return;
    }

    if (userData?.phone?.length < 10 || userData?.phone?.length > 12) {
      setUserError({
        phone: true,
      });
      return;
    }

    if (!userData.organization.match(/^[0-9a-zA-Z]+$/)) {
      setUserError({
        organization: true,
      });
      return;
    }

    axios
      .post(
        `${BootcampBackend}/submitHackathon/create`,
        {
          name: userData.name,
          phoneNumber: userData.phone,
          emailId: userData.emailId,
          Description: userData.description || "I want to host a hackathon.",
          designation: userData.designation,
          organization: userData.organization,
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.status.code === 201 || res.data.status.code === 200) {
          hideModal();
        }
      })
      .catch((err) => {
        // if(err.response.data.status.code === 404){
        // }
        // if(err.response.data.status.code === 500){
        //   console.log("Internal Server Error");
        // }
      });
  }

  //const e_id = "60fd50e33233120548e0e79d";

  async function fetchData() {
    try {
      let e_id = JSON.parse(localStorage.getItem("jwt")).eid;
      const res = await axios.get(
        `${API}/enterprise/dashboard?enterprise_id=${e_id}`
      );
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (!localStorage.getItem("jwt")) {
      history.push("/login");
    }
    // fetchData()
    //   .then((res) => {
        setIsLoading(false);
    //     if (res.data && Array.isArray(res.data.message))
    //       setHackathons(res.data.message);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  return (
    <div>
      <Header
        className="header"
        heading="My Hackathons"
      />
      <div className="container-fluid hackathonLandingContainer px-0">
        <div
          className={`${
            isSidebarCollapsed ? "collapsed-content-page" : "content-page"
          }`}
        >
          <Navbar />
          {/* <div className="py-2 d-flex align-items-center justify-content-end hackathonLandingHeader">
            <span className="col-md-7 pe-5 text-center Header-heading">
              My Hackathons
            </span>
          </div> */}
          <div className="pr-0 hackathonListContainer">
            {isLoading ? (
              <LoadingShape
                className="my-3"
                style={{ width: "15%", height: "25px" }}
              />
            ) : (
              <button
                onClick={() => {
                  setShow(true);
                }}
                className="secondaryColoredBtn my-3 requestHackathonBtn"
              >
                Request Hackathon <img className="ms-2" src={AddIcon} />
              </button>
            )}
            {isLoading
              ? Array.from(
                  {
                    length: 3,
                  },
                  (_, i) => (
                    <div key={i} className="hackathonDetailCard mb-4 d-flex">
                      <div className="col-md-4 col-lg-3  hackathonDetailCardImg">
                        <LoadingShape
                          variant="image"
                          style={{ height: "130px" }}
                        />
                      </div>
                      <div className="ps-4 inline-block hackathonDetailCardData w-100">
                        <div className="d-flex justify-content-between">
                          <div className="col-md-8 hackathonStatusInfo inline-block d-flex">
                            <LoadingShape
                              className="me-3"
                              style={{ width: "15%", height: "15px" }}
                            />
                            <LoadingShape
                              className="me-1"
                              style={{ width: "10%", height: "15px" }}
                            />
                          </div>
                          <LoadingShape
                            className="col-md-3 text-end paragraph"
                            style={{ width: "15%", height: "15px" }}
                          />
                        </div>
                        <div className="mt-3 mb-2">
                          <LoadingShape
                            style={{ width: "40%", height: "15px" }}
                          />
                        </div>
                        <div className="d-flex mt-3 pt-2">
                          <LoadingShape
                            className="me-3"
                            style={{ width: "15%", height: "15px" }}
                          />
                          <LoadingShape
                            style={{ width: "15%", height: "15px" }}
                          />
                        </div>
                        <div className="d-flex mt-2 pt-1">
                          <LoadingShape
                            className="me-3"
                            style={{ width: "15%", height: "25px" }}
                          />
                          <LoadingShape
                            className="me-3"
                            style={{ width: "15%", height: "25px" }}
                          />
                          <LoadingShape
                            className="me-3"
                            style={{ width: "15%", height: "25px" }}
                          />
                        </div>
                      </div>
                    </div>
                  )
                )
              : hackathons.map((hackathon, i) => {
                  return (
                    <div
                      key={i}
                      className="hackathonDetailCard mb-4 d-flex"
                      onClick={() => {
                        history.push(
                          `/myHackathons/${hackathon?.hackathon_id}`
                        );
                      }}
                    >
                      <div className="col-md-4 col-lg-3  hackathonDetailCardImg">
                        <img src={hackathon?.HImage} className="img-fluid" />
                      </div>
                      <div className="ps-4 inline-block hackathonDetailCardData w-100">
                        <div className="d-flex justify-content-between">
                          <div className="col-md-8 hackathonStatusInfo inline-block">
                            <button
                              className={`${
                                hackathon?.HStatus == "Expired"
                                  ? "hackathonStatusInactiveBtn"
                                  : "hackathonStatusActiveBtn"
                              } me-3`}
                            >
                              {/*hackathonStatusInactiveBtn*/}
                              <div className="me-2 mb-1 align-middle"></div>
                              {hackathon?.HStatus == "Expired"
                                ? "Expired"
                                : "Active"}
                            </button>
                            {hackathon?.prize && (
                              <span className="me-3">
                                <img className="me-2" src={hackathonPrize} />
                                {hackathon?.prize}
                              </span>
                            )}
                            {hackathon?.HStartDate && hackathon?.HEndDate && (
                              <span className="hackathonVerticalBar"></span>
                            )}

                            {hackathon?.HStartDate && hackathon?.HEndDate && (
                              <span className="ms-3">
                                <img className="me-2" src={hackathoneTime} />
                                {formatDate(hackathon?.HStartDate)} -{" "}
                                {formatDate(hackathon?.HEndDate)}
                              </span>
                            )}
                          </div>
                          <span className="col-md-4 mt-2 text-end paragraph">
                            Posted on{" "}
                            {moment(hackathon?.HStartDate).format("DD")}{" "}
                            {moment(hackathon?.HStartDate).format("MMM")}{" "}
                            {moment(hackathon?.HStartDate).format("YYYY")}
                          </span>
                        </div>
                        <div className="mt-3 cardHeading mb-2">
                          {hackathon?.HName}
                        </div>
                        <div className="hackathoneStatusbar mt-2">
                          <div
                            className={`${
                              hackathon?.HStatus == "Expired"
                                ? "hackathoneInactiveStatusbarValue"
                                : "hackathoneStatusbarValue"
                            }`}
                          ></div>
                        </div>
                        <div className="mt-2 sidebarInactiveText">
                          {hackathon?.leaderboard_participants && (
                            <span className="me-3">
                              Submissions -{" "}
                              {hackathon?.leaderboard_participants}
                            </span>
                          )}

                          <span className="hackathonVerticalBar"></span>
                          {hackathon?.registration && (
                            <span className="ms-3">
                              Registration - {hackathon?.registration}
                            </span>
                          )}
                        </div>
                        <div>
                          {hackathon?.top_3_rankers.map((ranker, i) => {
                            return (
                              <div
                                key={i}
                                className={`hackathonRanking mt-3 me-4 ${
                                  i === 0 ? "hackathonTopRanking" : ""
                                }`}
                              >
                                <img
                                  className="img-fluid me-2 hackathonRankingUserLogo"
                                  src={ranker?.ImageUrl}
                                />
                                {ranker?.Name}
                                {i < 3 && (
                                  <img
                                    src={
                                      i == 0
                                        ? hackathoneRanking1
                                        : i == 1
                                        ? rankingLogo2
                                        : rankingLogo3
                                    }
                                    className="hackathonRankingLogo"
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
          <RequestHackathon
            show={show}
            hideModal={hideModal}
            userError={userError}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
